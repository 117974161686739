import { useEffect, useState } from 'react'
import React from 'react'
import { Button } from 'react-components/atoms'
import { UTENTE } from 'utils/endpoint'
import { api } from 'utils'
import { connect, useSelector } from 'react-redux'
import { useRouter } from 'next/router'

api.defaults.xsrfCookieName = 'csrftoken'
api.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

const Toolbar = (props) => {
  const { utente, toolbar, toolbarPage = null, toolbarItem = null } = props

  if (!(utente.is_staff || utente.is_impersonate)) return null

  const [loading, setLoading] = useState(false)

  const handleSwitchMode = (edit) => {
    api
      .post(UTENTE + 'set_edit/', { edit })
      .then(() => {
        router.reload()
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    router.events.on('routeChangeStart', () => setLoading(true))
    router.events.on('routeChangeComplete', () => setLoading(false))
  }, [])

  const router = useRouter()

  const isAnteprima = router.asPath.includes('?anteprima')

  const { item } = useSelector((state) => state.toolbar)

  const realItem = !toolbarItem?.admin_url ? item : toolbarItem

  return (
    <div className="toolbar">
      {utente.is_impersonate ? (
        <div className="toolbar__user">
          Stai impersonando {utente.nome} {utente.cognome} -{' '}
          <a href="/d/impersonate/stop/">Smetti di impersonare</a>
        </div>
      ) : (
        <>
          <div className="toolbar__user">
            {utente.nome} {utente.cognome}
          </div>
          <section className="toolbar__left"></section>
          <section className="toolbar__right">
            {realItem?.admin_url && toolbarItem?.admin_url !== toolbarPage?.admin_url && (
              <a
                href={realItem.admin_url}
                className="button button--tertiary wbutton--sm"
                target="_blank"
              >
                Modifica {realItem.model_class?.capitalize()}
              </a>
            )}
            {toolbarPage?.admin_url && (
              <a
                href={toolbarPage.admin_url}
                className="button button--tertiary wbutton--sm"
                target="_blank"
              >
                Admin Pagina
              </a>
            )}

            {loading ? (
              <button className="button button--primary button--red wbutton--sm toolbar__right__caricamento">
                LOADING
              </button>
            ) : (
              !isAnteprima && (
                <>
                  {/* <a
                    className="button button--primary wbutton--sm toolbar__right__anteprima"
                    href={toolbar.route + '?anteprima'}
                    target="_blank"
                  >
                    VEDI ANTEPRIMA
                  </a> */}
                  {toolbar.edit ? (
                    <button
                      className="button button--primary wbutton--sm toolbar__right__pubblicata"
                      onClick={() => handleSwitchMode(false)}
                    >
                      Vedi pubblicata
                    </button>
                  ) : (
                    <Button
                      className="button"
                      colorVariant="orange"
                      onClick={() => handleSwitchMode(true)}
                      label="Modifica"
                    />
                  )}
                </>
              )
            )}
            {router.isPreview ? (
              <Button
                colorVariant="black"
                external
                label="Disattiva modalità preview"
                size="sm"
                href={`/api/stop-preview?secret=${process.env.PREVIEW_MODE_SECRET}&redirect=${router.asPath}`}
                className={'button'}
              />
            ) : (
              <Button
                colorVariant="black"
                external
                label="Attiva modalità preview"
                size="sm"
                href={`/api/preview?secret=${process.env.PREVIEW_MODE_SECRET}&redirect=${router.asPath}`}
                className={'button'}
              />
            )}
          </section>
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    utente: state.utente.utente,
    toolbar: state.toolbar,
  }
}

export default connect(mapStateToProps, null)(Toolbar)
